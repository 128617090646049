@keyframes slide {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.wrapper:nth-child(1) {
  animation-delay: 0s;
}
.wrapper:nth-child(2) {
  animation-delay: 10s;
}
.wrapperLeft:nth-child(3) {
  animation-delay: 21s;
}
.wrapperRight:nth-child(4) {
  animation-delay: 21s;
}
.wrapperLeft:nth-child(5) {
  animation-delay: 31s;
}
.wrapperRight:nth-child(6) {
  animation-delay: 31s;
}
.wrapperLeft:nth-child(7) {
  animation-delay: 41s;
}
.wrapperRight:nth-child(8) {
  animation-delay: 41s;
}

.wrapper {
  width: 75.5vw;
  height: 69.4vh;
  animation: slide 11s;
  top: 14vh;
  left: 22.5vw;
  opacity: 0;
  flex-shrink: 0;
  position: absolute;
  right: 2vw;
  object-fit: cover;
  border-radius: 0.5vw;
}

.wrapperLeft {
  width: 37.76vw;
  height: 95vh;
  animation: slide 12s;
  top: 2vh;
  left: 22vw;
  opacity: 0;
  flex-shrink: 0;
  position: absolute;
  right: 2vw;
  object-fit: cover;
  border-radius: 0.5vw;
}

.wrapperRight {
  width: 37.76vw;
  height: 95vh;
  animation: slide 12s;
  top: 2vh;
  left: 61vw;
  opacity: 0;
  flex-shrink: 0;
  position: absolute;
  right: 2vw;
  object-fit: cover;
  border-radius: 0.5vw;
}
