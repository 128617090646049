@keyframes progressSlide {
  0% {
    width: 0;
  }
  100% {
    width: 100vw;
  }
}

.progress {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1vh;
  z-index: 5000;
  background-color: rgba(236, 236, 236, 0.7);

  &__line {
    animation: progressSlide 21s linear;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    background-color: #1c6434;
    height: 1vh;
    width: 0;
  }
}
