.wrapper {
  display: flex;
  justify-content: center;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}

.background {
  width: 100vw;
  height: 100vh;
}

.loading {
  position: absolute;
  top: 50%;
  color: rgb(155, 157, 159);
  font-size: 36px;
}
