@import '../../../theme/variables';

span {
  font-weight: $font-semiBold;
}

@keyframes slide {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  98% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 2vw;
  bottom: 4vh;
  width: 96vw;
  height: 79vh;
  background-color: $color-light;
  border: 1px solid rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(80px);
  border-radius: 0.5vw;
  padding: 2% 2%;
  z-index: 1000;
  opacity: 0;
  animation: slide 20s;
}

.location {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3vh;

  &__name {
    font-size: $font-size-lx;
    line-height: $line-height-lx;
  }
}

.time {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 25%;
  border-right: none;

  &__time {
    text-align: right;
    font-size: $font-size-xl;
    line-height: $line-height-xl;
    font-weight: $font-bold;
  }

  &__date {
    text-align: right;
    font-size: $font-size-m;
    line-height: $line-height-m;
    font-weight: $font-semiBold;
    text-transform: capitalize;
  }
}

.weather {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 5vh;
  border-bottom: 1px solid $color-light-darker;
  margin-bottom: 4vh;
}

.details {
  display: flex;
  align-items: center;
  width: 30%;

  &__degree {
    font-size: 3vw;
    line-height: 3.2vw;
    font-weight: $font-bold;
  }

  &__icon {
    width: 25%;
    margin-right: 4%;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;

  &__innerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__iconSun {
    width: 14%;
    transform: translateX(-8%);
    margin-right: 1%;
  }

  &__iconWind {
    width: 12%;
    margin-right: 3%;
  }

  &__iconHumidity {
    width: 11%;
    margin-right: 4%;
  }

  &__iconPressure {
    width: 13%;
    margin-right: 2%;
    transform: translateX(-10%);
  }

  &__paragraph {
    font-size: $font-size-l;
    line-height: $line-height-l;
    font-weight: $font-medium;
    width: 100%;
  }
}

.forecast {
  &__name {
    font-size: $font-size-lx;
    line-height: $line-height-lx;
    margin-bottom: 1vh;
  }

  &__icon {
    margin-top: 1vh;
    position: relative;
    bottom: -1vh;
    transform: translateX(2.7vw);
    width: 3%;
    margin-right: 12.7vw;
  }
}

.chartBox {
  display: flex;
  flex-direction: row;
  margin-bottom: 2vh;
}

.legendBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2vw;
  align-items: flex-start;

  &__box {
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
  }

  &__line {
    width: 55px;
    height: 7px;
    margin-right: 5px;
    background-color: $color-dark;

    &Dot {
      @extend .legendBox__line;

      width: 5px;
    }
  }

  &__paragraph {
    color: #323232;
    font-weight: 600;
    font-size: 0.8vw;
    margin-left: 15px;
  }
}

.berg {
  display: flex;
  justify-content: center;
  margin-top: 4vh;

  &__content {
    font-size: $font-size-s;
  }

  &__logo {
    width: 4vw;
    height: 1.5vw;
    margin-right: 0.5vw;
  }
}
