//----- Time duration
$time-main-infoBar: 52s;
$time-main-progress: 51s;

//----- Fonts
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semiBold: 600;
$font-bold: 700;

$font-size-s: 0.9vw;
$font-size-m: 1vw;
$font-size-l: 1.1vw;
$font-size-lx: 1.4vw;
$font-size-xl: 2vw;
$font-size-xll: 2.2vw;

$line-height-s: 1vw;
$line-height-m: 1.2vw;
$line-height-l: 1.4vw;
$line-height-lx: 1.6vw;
$line-height-xl: 2.8vw;
$line-height-xll: 2.9vw;

//----- Colors
$color-black: #000000;
$color-dark: #323232;
$color-light: rgba(255, 255, 255, 0.7);
$color-light-darker: #7e8d94;
$color-red: #dd4747;
$color-green: #1c6434;
$color-white: #ffffff;
