@import '../../../theme/variables';

@keyframes slide {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  98% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 2vw;
  top: 3vh;
  width: 96vw;
  height: 11vh;
  background-color: $color-light;
  border: 1px solid rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(80px);
  border-radius: 0.5vw;
  padding: 1% 1.7% 1% 2%;
  z-index: 1000;
  opacity: 0;
  animation: slide 20s;
}

.innerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.icon {
  width: 16.5vw; //317px
  height: auto;
}

.time {
  display: flex;
  align-items: center;
  height: 100%;

  &__date {
    font-size: 1.2vw;
    font-weight: $font-semiBold;
    text-transform: capitalize;
  }

  &__line {
    width: 1px;
    height: 100%;
    background-color: $color-light-darker;
    margin-left: 3vw;
    margin-right: 3vw;
  }

  &__time {
    font-size: $font-size-xll;
    font-weight: $font-semiBold;
    width: 100px;
  }
}
