@import '../../../theme/variables';

@keyframes slide {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

span {
  font-weight: $font-semiBold;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: 1.5vw;
  top: 2vh;
  min-width: 18vw;
  max-width: 19.5vw;
  height: 95vh;
  background-color: $color-light;
  border: 1px solid rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(80px);
  border-radius: 0.5vw;
  padding: 2% 1%;
  z-index: 1000;
  opacity: 0;
  animation: slide $time-main-infoBar;
}

.time {
  display: flex;
  align-items: center;
  height: 25%;
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid $color-light-darker;
  margin-bottom: 5vh;
  padding-bottom: 5vh;

  &__icon {
    width: 12vw; //317px
    height: auto;
    margin-bottom: 3vh;
  }

  &__time {
    text-align: right;
    font-size: $font-size-xl;
    line-height: $line-height-xl;
    font-weight: $font-bold;
  }

  &__date {
    text-align: right;
    font-size: $font-size-m;
    line-height: $line-height-m;
    font-weight: $font-semiBold;
    text-transform: capitalize;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 50%;
  padding-left: 3%;

  &__innerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5vh;
  }

  &__iconWind {
    width: 12%;
    margin-right: 3%;
  }

  &__iconHumidity {
    width: 11%;
    margin-right: 4%;
  }

  &__iconPressure {
    width: 13%;
    margin-right: 2%;
    transform: translateX(-10%);
  }

  &__paragraph {
    font-size: $font-size-m;
    line-height: $line-height-m;
    font-weight: $font-medium;
    width: 14vw;
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &__logo {
    width: 12vw;
    height: auto;
    position: absolute;
  }
}

.weather {
  display: flex;
  align-items: center;
  width: 100%;
  height: 20%;
  border-bottom: 1px solid $color-light-darker;
  margin-bottom: 7vh;
  padding-left: 3%;
  padding-bottom: 5vh;

  &__degree {
    font-size: $font-size-xl;
    line-height: $line-height-xl;
    font-weight: $font-bold;
  }

  &__icon {
    min-width: 24%;
    margin-right: 4%;
  }
}

.berg {
  display: flex;
  width: 100%;
  justify-content: center;

  &__content {
    font-size: $font-size-s;
  }

  &__logo {
    width: 4vw;
    height: 1.5vw;
    margin-right: 0.5vw;
  }
}
